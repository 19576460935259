<template>

  <div>

    <el-button
      @click="goToIdP"
    >
      Войти с помощью OpenID
    </el-button>

<!--    <el-button-->
<!--      @click="logout"-->
<!--    >-->
<!--      выйти-->
<!--    </el-button>-->

  </div>
</template>

<script>


import requestSender from "@/api/base/requestSender";

export default {
  name: "open-id",

  props: {},
  data() {
    return {
      code: null,
    }
  },

  beforeMount() {
    this.code = this.$route.query.code;
    if( this.code ){
      this.checkCode();
    } else {
      const authMethodsCount = this.$companyConfiguration.auth.getEnabledAuthMethods().length;
      if( authMethodsCount === 1 ){
        this.goToIdP();
      }
    }
  },
  methods: {
    goToIdP(){
      this.$store.dispatch('setLoadingLogin', true);
      const methodConfig = this.$companyConfiguration.auth.getAuthMethodConfig('openId');
      window.location.href = this.$companyConfiguration.helpers.buildUrl(
        methodConfig.domain+'/authorize',
        {
          client_id: methodConfig.clientId,
          redirect_uri: methodConfig.redirectUri,
          scope: 'openid profile email phone insurance employeeguid',
          response_type: 'code',
        }
      )
    },

    checkCode(){
      this.$store.dispatch('setLoadingLogin', true);
      requestSender('post', 'auth/open-id/login', {
        code: this.code,
      })
        .then(data => {
          localStorage.setItem('jwt', data.token);
          localStorage.setItem('refresh_token', data.refresh_token);
          localStorage.setItem('last_login_method', 'openId');

          this.$router.push({name: 'Home'});
        })
        .catch(()=>{
          this.$router.push({name: 'Login'});
        })
        .finally(()=>{
          this.$store.dispatch('setLoadingLogin', false);
        })
    },


    logout() {
      const url = 'https://dev-op10ami2rb7v24hh.us.auth0.com/v2/logout?client_id=BSwbJBdds155uVaZ4OeRFGj5A9O9yILa&redirect_uri=http://localhost:8082/?asd=234'
      window.location.href=url;
      // this.$auth.logout();
      // this.$router.push({ path: '/' });
    },

  },

}
</script>

<style>

</style>